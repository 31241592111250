const axios = require("axios");

export const amplifyGet = async (
  url: string,
  options?: { with_account_id: boolean },
) => {
  const result = await axios.get(
    `${process.env.NEXT_PUBLIC_ADS_SERVICE_BASE_URL}${url}`,
    {
      headers: {
        Authorization: `Authorization: ${localStorage.getItem("api_token")}`,
      },
      params: options?.with_account_id
        ? { account_id: localStorage.getItem("accountId") }
        : undefined,
    },
  );
  return result.data;
};
