import React from "react";
import usePasses from "@hooks/passes";
import { GiftCardList } from "@components/gift-card-list";

export default function PassesDisplay({ account_id }: { account_id?: string }) {
  const { passes } = usePasses({
    account_id,
  });

  if (!passes) return <div>Loading...</div>;
  return (
    <div>
      <GiftCardList products={passes} />
    </div>
  );
}
