import { useEffect, useState } from "react";
import { Product } from "@models/models";
import { useLazyFindEventsQuery } from "@store/adsSlice";
import useLocationInfo from "@hooks/location-info";

type EventsProps = {
  type?: string;
  account_id?: string;
  platform_id?: number;
  slug?: string;
};
export default function useEvents(props: EventsProps) {
  const [findEvents] = useLazyFindEventsQuery();
  const [events, setEvents] = useState<Product[]>();
  const [render, setRender] = useState(0);
  const location = useLocationInfo();

  useEffect(() => {
    if (!location) return;
    findEvents({
      ...props,
      latitude: location.lat,
      longitude: location.lon,
    }).then((d) => setEvents(d.data));
  }, [props.type, props.account_id, props.platform_id, render, location]);

  const refetch = () => setRender((r) => r + 1);

  return { events, refetch };
}
