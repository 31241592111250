import React from "react";

import { ComponentConfig } from "@measured/puck";
import ComicProfileDisplay from "@components/puck/page-builder/data-components/comic-profile-display";
import {
  ContainerOptions,
  spacingStyleProps,
} from "@components/puck/page-builder/formatting/container-options";
import axios from "axios";
import { Participant } from "@models/models";
import container from "@components/puck/page-builder/fields/container";
import tailwindAlign from "@components/puck/page-builder/fields/tailwind-align";

export type ComicProfileProps = {
  participant: Participant;
  container_options: ContainerOptions;
  tailwind_align: string;
};

export const ComicProfile: ComponentConfig<ComicProfileProps> = {
  label: "Comic Profile",
  fields: {
    participant: {
      label: "Comic",
      type: "external",
      showSearch: true,
      getItemSummary: (item: any) => item.name,
      mapRow: (item: any) => ({ Name: item.name }),
      fetchList: async ({ query }) => {
        const result = await axios.get(
          `${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/store/participants`,
        );
        const participants = result.data as Participant[];
        return !query
          ? participants
          : participants.filter((p) => p.name.toLowerCase().includes(query));
      },
    },
    tailwind_align: tailwindAlign,
    container_options: container,
  },

  render: ({
    participant,
    container_options,
    tailwind_align,
  }: ComicProfileProps) => {
    if (!participant)
      return (
        <div className={"p-2 text-center w-full border b-1"}>
          Select a comic from the sidebar
        </div>
      );
    return (
      <div
        className={`flex ${tailwind_align}`}
        style={{ ...spacingStyleProps(container_options) }}
      >
        <ComicProfileDisplay slug={participant.slug} />
      </div>
    );
  },
};
