import React from "react";
import { ContainerOptions } from "@components/puck/page-builder/formatting/container-options";
import ColorPicker from "@components/forms/color-picker";
import SmallInput from "@components/puck/page-builder/components/small-input";
import { FieldLabel } from "@measured/puck";

export default function SelectContainerOptions({
  values,
  onChange,
}: {
  values: ContainerOptions;
  onChange: any;
}) {
  return (
    <div className={"flex flex-col gap-y-6"}>
      <div>
        <FieldLabel label={"Padding"}>
          <div className={"grid grid-cols-4"}>
            <SmallInput
              label={"Top"}
              value={values?.padding_top}
              name={"padding_top"}
              onChange={(value) => {
                onChange({ ...values, padding_top: value });
              }}
            />
            <SmallInput
              label={"Left"}
              value={values?.padding_left}
              name={"padding_left"}
              onChange={(value) => {
                onChange({ ...values, padding_left: value });
              }}
            />
            <SmallInput
              label={"Right"}
              value={values?.padding_right}
              name={"padding_right"}
              onChange={(value) => {
                onChange({ ...values, padding_right: value });
              }}
            />
            <SmallInput
              label={"Bottom"}
              value={values?.padding_bottom}
              name={"padding_bottom"}
              onChange={(value) => {
                onChange({ ...values, padding_bottom: value });
              }}
            />
          </div>
        </FieldLabel>
      </div>
      <div>
        <FieldLabel label={"Margin"}>
          <div className={"grid grid-cols-4"}>
            <SmallInput
              label={"Top"}
              value={values?.margin_top}
              name={"margin_top"}
              onChange={(value) => {
                onChange({ ...values, margin_top: value });
              }}
            />
            <SmallInput
              label={"Left"}
              value={values?.margin_left}
              name={"margin_left"}
              onChange={(value) => {
                onChange({ ...values, margin_left: value });
              }}
            />
            <SmallInput
              label={"Right"}
              value={values?.margin_right}
              name={"margin_right"}
              onChange={(value) => {
                onChange({ ...values, margin_right: value });
              }}
            />
            <SmallInput
              label={"Bottom"}
              value={values?.margin_bottom}
              name={"margin_bottom"}
              onChange={(value) => {
                onChange({ ...values, margin_bottom: value });
              }}
            />
          </div>
        </FieldLabel>
      </div>
      <div>
        <FieldLabel label={"Border Width"}>
          <div className={"grid grid-cols-4"}>
            <SmallInput
              label={"Top"}
              value={values?.border_width_top}
              name={"border_width_top"}
              onChange={(value) => {
                onChange({ ...values, border_width_top: value });
              }}
            />
            <SmallInput
              label={"Right"}
              value={values?.border_width_right}
              name={"border_width_right"}
              onChange={(value) => {
                onChange({ ...values, border_width_right: value });
              }}
            />
            <SmallInput
              label={"Bottom"}
              value={values?.border_width_bottom}
              name={"border_width_bottom"}
              onChange={(value) => {
                onChange({ ...values, border_width_bottom: value });
              }}
            />
            <SmallInput
              label={"Left"}
              value={values?.border_width_left}
              name={"border_width_left"}
              onChange={(value) => {
                onChange({ ...values, border_width_left: value });
              }}
            />
          </div>
        </FieldLabel>
      </div>
      <div>
        <FieldLabel label={"Border Rounding"}>
          <SmallInput
            label={"Pixels"}
            value={values?.border_radius}
            name={"border_radius"}
            onChange={(value) => {
              onChange({ ...values, border_radius: value });
            }}
          />
        </FieldLabel>
      </div>
      <div>
        <FieldLabel label={"Border Color"}>
          <ColorPicker
            onChange={(value) => onChange({ ...values, border_color: value })}
            value={values?.border_color}
          />
        </FieldLabel>
      </div>
      <div>
        <FieldLabel label={"Background Color"}>
          <ColorPicker
            onChange={(value) =>
              onChange({ ...values, background_color: value })
            }
            value={values?.background_color}
          />
        </FieldLabel>
      </div>
    </div>
  );
}
