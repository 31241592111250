import { Brand, Venue } from "@models/models";
import { amplifyGet } from "@utils/http";
import { Field } from "@measured/puck";

const field: Field<Brand | undefined> = {
  type: "external",
  label: "Brand",
  showSearch: true,
  getItemSummary: (item: Brand) => item.name,
  mapRow: (item: Venue) => ({
    Name: item.name,
  }),
  fetchList: async ({ query }) => {
    const result = await amplifyGet(
      `/api/accounts/${localStorage.getItem("accountId")}/brands`,
    );
    const brands = result.data as Brand[];
    return !query
      ? brands
      : brands.filter((b) => b.name.toLowerCase().includes(query));
  },
};

export default field;
