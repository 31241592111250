import { selectAccount } from "@store/account-slice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useLazyGetMailingListsByAccountIdQuery } from "@store/adsSlice";

export default function SelectMailingListId({
  onSelectMailingList,
}: {
  onSelectMailingList: (value: string) => void;
}) {
  const account = useSelector(selectAccount);
  const [getMailingListsByAccountId] = useLazyGetMailingListsByAccountIdQuery();
  useEffect(() => {
    if (!account) return;
    getMailingListsByAccountId(account.id).then((r) => {
      if (r.data && r.data.length) {
        onSelectMailingList(r.data[0].id);
      }
    });
  }, [account, getMailingListsByAccountId]);

  return <div></div>;
}
