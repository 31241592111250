"use client";
import React, { useEffect, useState } from "react";

import { ComponentConfig, FieldLabel } from "@measured/puck";
import container from "@components/puck/page-builder/fields/container";
import {
  containerDefaults,
  ContainerOptions,
  spacingStyleProps,
} from "@components/puck/page-builder/formatting/container-options";
import TiptapEditor from "@components/tiptap-editor";

export type RichTextProps = {
  html: string;
  align: "left" | "right" | "center";
  container_options: ContainerOptions;
  padding: string;
};

function RichTextEditorWrapper({
  onChange,
  defaultValue,
}: {
  defaultValue: string;
  onChange: (value: string) => void;
}) {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    onChange(value);
  }, [value]);
  return (
    <div className={"mb-10"}>
      <FieldLabel label={"Text"}>
        <TiptapEditor content={value} onUpdate={setValue} />
      </FieldLabel>
    </div>
  );
}
export const RichText: ComponentConfig<RichTextProps> = {
  label: "Rich Text",
  fields: {
    html: {
      type: "custom",
      render: ({ name, onChange, value }) => {
        return (
          <RichTextEditorWrapper
            defaultValue={value}
            onChange={(value) => {
              onChange(value);
            }}
          />
        );
      },
    },
    align: {
      label: "Align",
      type: "radio",
      options: [
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
        { label: "Right", value: "right" },
      ],
    },
    container_options: container,
    padding: { type: "text", label: "Padding" },
  },
  defaultProps: {
    align: "center",
    html: "",
    padding: "12px",
    container_options: containerDefaults,
  },

  render: ({ html, align, padding, container_options }: any) => {
    return (
      <>
        {html && html.length > 0 && html !== "<p><br></p>" ? (
          <div style={{ ...spacingStyleProps(container_options) }}>
            <div style={{ padding, textAlign: align }}>
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
          </div>
        ) : (
          <div className={"p-2 text-center w-full border b-1"}>
            Start typing text in the sidebar.
          </div>
        )}
      </>
    );
  },
};
