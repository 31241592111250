import { Form, Formik } from "formik";
import { string } from "yup";
import { useMedusa } from "@hooks/medusa";
import { useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";
import { ProductVariant } from "@models/models";
import { useErrorHandler } from "@hooks/error-handler";
import { TextInput } from "@components/forms/text-input";
import Image from "next/image";
import { getVariantShowtimeOnly } from "@components/variant-info";
import { FaCheckCircle } from "react-icons/fa";
import { useRedeemTicketMutation } from "@store/adsSlice";

export default function RedeemTicket({
  button_label,
  success_message,
  variant_id,
  intro_text,
}: {
  button_label: string;
  success_message: string;
  variant_id: string;
  intro_text: string;
}) {
  const { medusa } = useMedusa();
  const searchParams = useSearchParams();
  const show_id = searchParams.get("show_id");
  const redeemed_by = searchParams.get("redeemed_by");
  const [variant, setVariant] = useState<ProductVariant>();
  const [success, setSuccess] = useState(false);
  const [redeemTicket] = useRedeemTicketMutation();
  const handleRedeemTicket = useErrorHandler(async (values) => {
    await redeemTicket(values).unwrap();
    setSuccess(true);
  });

  const loadVariant = async (variant_id: string) => {
    const resp = await medusa.products.variants.retrieve(variant_id);
    if (resp.response.status === 200) {
      setVariant(resp.variant as unknown as ProductVariant);
    } else {
      throw new Error(`${resp.response.statusText} - Could not find show.`);
    }
  };
  useEffect(() => {
    if (show_id) {
      loadVariant(show_id);
    } else if (variant_id) {
      loadVariant(variant_id);
    }
  }, [show_id, variant_id]);

  if (!variant) return <div>Loading...</div>;
  return (
    <div>
      <div className="card lg:card-side shadow-xl">
        <figure>
          <Image
            width={400}
            height={400}
            src={variant.product.thumbnail as string}
            alt={variant.product.title}
          />
        </figure>
        <div className="card-body flex items-center">
          <h2 className="card-title">{variant.product.title}</h2>
          <h3>{getVariantShowtimeOnly(variant)}</h3>
          {success ? (
            <div
              className={
                "flex flex-col mt-4 gap-y-4 justify-center text-center items-center border-2 border-gray-300 p-4 rounded-xl"
              }
            >
              <div>
                <FaCheckCircle className={"text-green-600 text-7xl"} />
              </div>
              <div>{success_message}</div>
            </div>
          ) : (
            <>
              <p>{intro_text}</p>
              <Formik
                initialValues={{
                  code: string,
                  variant_id: variant.id,
                  redeemed_by,
                }}
                onSubmit={handleRedeemTicket}
              >
                <Form>
                  <div className={"flex flex-col items-center gap-6 max-w-lg"}>
                    <TextInput label={"Code"} name={"code"} />
                    <div>
                      <button className={"btn btn-primary"}>
                        {button_label}
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
