import { useGetPassesQuery } from "@store/adsSlice";

type PassesProps = {
  account_id?: string;
};
export default function usePasses(props: PassesProps) {
  const { data: passes } = useGetPassesQuery({
    ...props,
  });

  return { passes };
}
