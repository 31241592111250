import { Participant } from "../../models/models";
import Image from "next/image";
import * as React from "react";

export default function Avatar({ participant }: { participant: Participant }) {
  return (
    <div className="avatar flex justify-center">
      <div className="w-32 rounded-xl">
        <Image
          alt={participant.name}
          src={participant.picture}
          height={300}
          width={300}
        />
      </div>
    </div>
  );
}
