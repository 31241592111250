import React from "react";

import { ComponentConfig } from "@measured/puck";
import Image from "next/image";
import {
  imageOptions,
  suffixPixel,
} from "@components/puck/page-builder/formatting/image-options";
import container from "@components/puck/page-builder/fields/container";
import {
  containerDefaults,
  ContainerOptions,
  spacingStyleProps,
} from "@components/puck/page-builder/formatting/container-options";
import tailwindAlign from "@components/puck/page-builder/fields/tailwind-align";
import brand from "@components/puck/page-builder/fields/brand";
import { Brand } from "@models/models";

export type BrandLogoProps = {
  width: string;
  rounding: string;
  container_options: ContainerOptions;
  tailwind_align: string;
  brand?: Brand;
};

export const BrandLogo: ComponentConfig<BrandLogoProps> = {
  label: "Brand Logo",
  fields: {
    brand: brand,
    tailwind_align: tailwindAlign,
    width: {
      type: "text",
      label: "Image Width",
    },
    rounding: {
      label: "Rounding",
      type: "select",
      options: imageOptions,
    },
    container_options: container,
  },
  defaultProps: {
    rounding: "",
    width: "",
    container_options: containerDefaults,
    tailwind_align: "justify-left",
  },

  render: ({
    brand,
    width,
    rounding,
    container_options,
    tailwind_align,
  }: BrandLogoProps) => {
    if (!brand)
      return (
        <div className={"p-2 text-center w-full border b-1"}>
          Select a brand from the sidebar
        </div>
      );
    return (
      <div
        className={`flex ${tailwind_align}`}
        style={{ ...spacingStyleProps(container_options) }}
      >
        <Image
          className={rounding}
          style={{ width: suffixPixel(width) }}
          alt={""}
          src={brand.thumbnail}
          width={400}
          height={400}
        />
      </div>
    );
  },
};
