import React from "react";
import { ComponentConfig } from "@measured/puck";
import { DropZone } from "@measured/puck";
import {
  containerDefaults,
  ContainerOptions,
  spacingStyleProps,
} from "@components/puck/page-builder/formatting/container-options";
import SelectContainerOptions from "@components/puck/page-builder/components/select-container-options";

export type ColumnsProps = {
  distribution: "start" | "center" | "end" | "between";
  spacing: "none" | "small" | "medium" | "large" | "xl";
  columns: {}[];
  align: "items-start" | "items-center" | "items-end";
  container_options: ContainerOptions;
};

export const FlexColumns: ComponentConfig<ColumnsProps> = {
  label: "Columns",
  fields: {
    columns: {
      label: "Columns",
      getItemSummary: (item, index) => `Column #${(index ? index : 0) + 1}`,
      type: "array",
      arrayFields: {},
    },
    distribution: {
      label: "Column Distribution",
      type: "radio",
      options: [
        {
          value: "start",
          label: "Left",
        },
        {
          value: "center",
          label: "Center",
        },
        {
          value: "end",
          label: "Right",
        },
        {
          value: "between",
          label: "Stretch",
        },
      ],
    },
    spacing: {
      label: "Horizontal Spacing",
      type: "radio",
      options: [
        {
          value: "none",
          label: "None",
        },
        {
          value: "small",
          label: "S",
        },
        {
          value: "medium",
          label: "M",
        },
        {
          value: "large",
          label: "L",
        },
        {
          value: "extra-large",
          label: "XL",
        },
      ],
    },
    align: {
      label: "Alignment",
      type: "radio",
      options: [
        {
          value: "items-start",
          label: "Top",
        },
        {
          value: "items-center",
          label: "Center",
        },
        {
          value: "items-end",
          label: "Bottom",
        },
      ],
    },
    container_options: {
      type: "custom",
      render: ({ onChange, field, value }) => {
        return <SelectContainerOptions values={value} onChange={onChange} />;
      },
    },
  },
  defaultProps: {
    columns: [{}, {}],
    distribution: "start",
    spacing: "medium",
    align: "items-center",
    container_options: containerDefaults,
  },
  render: ({ columns, align, container_options, distribution, spacing }) => {
    return (
      <div
        style={{ ...spacingStyleProps(container_options) }}
        className={`flex flex-col ${align} md:flex-row ${spacingToGap(spacing)} ${distributionToFlexType(distribution)}`}
      >
        {columns.map((item, idx) => (
          <div key={idx} style={{ minWidth: "50px" }}>
            <DropZone
              style={{
                minHeight: "200px",
                minWidth: "300px",
                borderStyle: "dotted",
                borderWidth: "1px",
                margin: "10px",
              }}
              zone={`column-${idx}`}
            />
          </div>
        ))}
      </div>
    );
  },
};

function spacingToGap(spacing: string): string {
  switch (spacing) {
    case "none":
      return "";
    case "small":
      return "gap-2";
    case "medium":
      return "gap-6";
    case "large":
      return "gap-10";
    case "extra-large":
      return "gap-14";
    default:
      return "gap-2";
  }
}

function distributionToFlexType(distribution: string): string {
  return `justify-${distribution}`;
}
