import { selectAccount } from "@store/account-slice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function SelectPlatformId({
  onSelectPlatform,
}: {
  onSelectPlatform: (value: number) => void;
}) {
  const account = useSelector(selectAccount);
  useEffect(() => {
    if (!account) return;
    onSelectPlatform(account.platform_id);
  }, [account]);

  return <div></div>;
}
