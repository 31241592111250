import React from "react";

import { ComponentConfig } from "@measured/puck";
import { EventDisplayMode, Venue } from "@models/models";
import SelectAccountId from "@components/puck/page-builder/components/select-account-id";
import { Message } from "@components/message";
import EventDisplay from "@components/puck/page-builder/data-components/event-display";
import venue from "@components/puck/page-builder/fields/venue";
import SelectPlatformId from "@components/puck/page-builder/components/select-platform-id";

export type EventProps = {
  venue?: Venue;
  account?: string;
  platform?: number;
  source: "account" | "venue" | "platform";
  show_calendar_option: boolean;
  default_display_view: EventDisplayMode;
};

export const UpcomingEvents: ComponentConfig<EventProps> = {
  label: "Upcoming Events",
  fields: {
    show_calendar_option: {
      type: "radio",
      label: "Show Calendar Option",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    default_display_view: {
      type: "radio",
      label: "Default Display View",
      options: [
        { label: "Calendar", value: "calendar" },
        { label: "Images", value: "list" },
      ],
    },
    source: {
      type: "select",
      label: "Source",
      options: [
        { label: "Account", value: "account" },
        { label: "Platform", value: "platform" },
        { label: "Venue", value: "venue" },
      ],
    },
    venue,
    account: {
      type: "custom",
      render: ({ onChange }) => <SelectAccountId onSelectAccount={onChange} />,
    },
    platform: {
      type: "custom",
      render: ({ onChange }) => (
        <SelectPlatformId onSelectPlatform={onChange} />
      ),
    },
  },
  defaultProps: {
    source: "platform",
    show_calendar_option: false,
    default_display_view: EventDisplayMode.LIST,
  },
  render: ({
    venue,
    account,
    platform,
    source,
    show_calendar_option,
    default_display_view,
  }: EventProps) => {
    if (source == "venue" && !venue) {
      return error(
        "You have selected Venue as the source, so please select a venue.",
      );
    }
    if (source === "account" && account) {
      return (
        <EventDisplay
          default_display_view={default_display_view}
          show_calendar_option={show_calendar_option}
          account_id={account}
          event_count={10}
        />
      );
    } else if (source === "venue" && venue) {
      return (
        <EventDisplay
          default_display_view={default_display_view}
          show_calendar_option={show_calendar_option}
          slug={venue.slug}
          event_count={10}
        />
      );
    } else if (source === "platform" && platform) {
      return (
        <EventDisplay
          default_display_view={default_display_view}
          show_calendar_option={show_calendar_option}
          platform_id={platform}
          event_count={10}
        />
      );
    } else {
      return error("No source found");
    }
  },
};

function error(message: string) {
  return (
    <Message message={message} title={"Invalid Values"} type={"warning"} />
  );
}
