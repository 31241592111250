export const imageOptions = [
  { label: "None", value: "" },
  { label: "Circle", value: "rounded rounded-full" },
  { label: "Squircle", value: "mask mask-squircle" },
  { label: "Hexagon", value: "mask mask-hexagon" },
  { label: "Triangle", value: "mask mask-triangle" },
];

export function suffixPixel(value: string): string {
  if (!value) return "";
  return `${value}px`;
}
