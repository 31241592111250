import {
  Product,
  PRODUCT_TYPE_DIGITAL,
  PRODUCT_TYPE_EVENT,
} from "@models/models";
import EventProductDetailWrapper from "@components/event-product-detail-wrapper";
import DigitalProductDetailWrapper from "@components/digital-product-detail-wrapper";
import React from "react";

export default function ProductDisplay({ product }: { product: Product }) {
  return (
    <div>
      {product.type_id === PRODUCT_TYPE_EVENT && (
        <EventProductDetailWrapper productId={product.id} />
      )}
      {(product.type_id === PRODUCT_TYPE_DIGITAL || product.isPass) && (
        <DigitalProductDetailWrapper productId={product.id} />
      )}{" "}
    </div>
  );
}
