import React from "react";

import { ComponentConfig } from "@measured/puck";
import SelectMailingListId from "@components/puck/page-builder/components/select-mailing-list-id";
import MailingListSignUp from "@components/mailing-list-sign-up";
import {
  containerDefaults,
  ContainerOptions,
  spacingStyleProps,
} from "@components/puck/page-builder/formatting/container-options";
import container from "@components/puck/page-builder/fields/container";

export type SubscribeToMailingListProps = {
  mailing_list_id: string;
  container_options: ContainerOptions;
};

export const SubscribeToMailingList: ComponentConfig<SubscribeToMailingListProps> =
  {
    label: "Subscribe to Mailing List",
    fields: {
      mailing_list_id: {
        type: "custom",
        render: ({ onChange }) => (
          <SelectMailingListId onSelectMailingList={onChange} />
        ),
      },
      container_options: container,
    },
    defaultProps: {
      mailing_list_id: "",
      container_options: containerDefaults,
    },

    render: ({
      mailing_list_id,
      container_options,
    }: SubscribeToMailingListProps) => {
      if (!mailing_list_id) return <div></div>;
      return (
        <div style={{ ...spacingStyleProps(container_options) }}>
          <MailingListSignUp mailingListId={mailing_list_id} />
        </div>
      );
    },
  };
