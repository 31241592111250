import { Field } from "@measured/puck";

const field: Field<string> = {
  type: "radio",
  label: "Align",
  options: [
    { label: "Left", value: "justify-start" },
    { label: "Center", value: "justify-center" },
    { label: "Right", value: "justify-end" },
  ],
};

export default field;
