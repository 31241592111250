"use client";
import { Product } from "@models/models";
import React from "react";
import { CityName } from "@components/city-name";
import Image from "next/image";
import Link from "next/link";
import {
  consolidatedShowtimes,
  firstVariant,
} from "@utils/event-display-helpers";
import { formatMoneyAmount } from "@utils/price";
import { MoneyAmount } from "@medusajs/medusa";
import StockIndicator from "@components/stock-indicator";
import { isProductInStock } from "@utils/cart";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export default function EventThumbnailList({
  products,
}: {
  products: Product[];
}) {
  const product_list_background_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.product_list_background_color,
    ),
  );
  if (!product_list_background_color) return <div>Loading...</div>;
  return (
    <div className="grid grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
      {products.length !== 0 &&
        products.map((product, index) => (
          <React.Fragment key={product.id}>
            {product.variants.length !== 0 && (
              <div
                style={{ backgroundColor: product_list_background_color }}
                className={`group relative border border-gray-200 rounded-lg flex flex-col overflow-hidden`}
              >
                {" "}
                <CityName venue={product.venueArrangement.venue} />
                <div className="bg-gray-200">
                  <Image
                    priority={index < 12}
                    src={product.thumbnail as string}
                    alt={product.title}
                    width={500}
                    height={500}
                  />
                </div>
                <div className="flex-1 p-4 space-y-2 flex flex-col">
                  <h3 className="text-sm font-medium text-gray-900">
                    <Link href={`/e/${product.handle}`}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.title}
                    </Link>
                  </h3>
                  <div className="flex-1 flex flex-col justify-end">
                    {consolidatedShowtimes(product).map((v) => (
                      <p
                        key={v}
                        className="text-xs md:text-sm italic text-gray-500"
                      >
                        {v}
                      </p>
                    ))}
                    <p className="text-base font-medium text-gray-900">
                      {formatMoneyAmount(
                        firstVariant(product.variants)
                          ?.prices[0] as MoneyAmount,
                      )}
                    </p>
                  </div>
                  <StockIndicator inStock={isProductInStock(product)} />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      {products.length === 0 && <div>No events found.</div>}
    </div>
  );
}
