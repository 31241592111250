import React from "react";

import SelectContainerOptions from "@components/puck/page-builder/components/select-container-options";
import { Field } from "@measured/puck";
import { ContainerOptions } from "@components/puck/page-builder/formatting/container-options";

const field: Field<ContainerOptions> = {
  type: "custom",
  render: ({ onChange, value }) => {
    return <SelectContainerOptions values={value} onChange={onChange} />;
  },
};

export default field;
