"use client";
import { Product } from "@models/models";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { createSelectItemPayload, sendGaEvent } from "@utils/google-analytics";
import { formatMoneyAmount } from "@utils/price";
import { MoneyAmount } from "@medusajs/medusa";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export function GiftCardList({ products }: { products: Product[] }) {
  const product_list_background_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.product_list_background_color,
    ),
  );
  if (!products || !product_list_background_color) return <div>Loading...</div>;
  return (
    <div className="grid grid-cols-2 gap-y-4 gap-x-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
      {products &&
        products.length !== 0 &&
        products.map((product, index) => (
          <React.Fragment key={product.id}>
            {
              <div
                style={{ backgroundColor: product_list_background_color }}
                className={`group relative border border-gray-200 rounded-lg flex flex-col overflow-hidden`}
              >
                <div className="bg-gray-200">
                  <Image
                    priority={index < 12}
                    src={product.thumbnail as string}
                    alt={product.title}
                    width={500}
                    height={500}
                  />
                </div>
                <div className="flex-1 p-4 space-y-2 flex flex-col">
                  <h3 className="text-sm font-medium text-gray-900">
                    <Link
                      href={`/e/${product.handle}`}
                      onClick={(e) =>
                        sendGaEvent(
                          "select_item",
                          createSelectItemPayload(
                            product,
                            product.id,
                            "gift-card-list",
                          ),
                        )
                      }
                    >
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.title}
                    </Link>
                  </h3>
                  <div className="flex-1 flex flex-col justify-end">
                    <p className="text-base font-medium text-gray-900">
                      {formatMoneyAmount(
                        product.variants[0].prices[0] as MoneyAmount,
                      )}
                    </p>
                  </div>
                </div>
              </div>
            }
          </React.Fragment>
        ))}
      {products && products.length === 0 && (
        <div>No gift cards or passes found.</div>
      )}
    </div>
  );
}
