import React from "react";

import { ComponentConfig } from "@measured/puck";
import SelectAccountId from "@components/puck/page-builder/components/select-account-id";
import GiftCardsDisplay from "@components/puck/page-builder/data-components/gift-cards-display";

export type GiftCardsProps = {
  account?: string;
  source: "account";
};

export const GiftCards: ComponentConfig<GiftCardsProps> = {
  label: "Gift Cards",
  fields: {
    source: {
      type: "select",
      label: "Source",
      options: [{ label: "Account", value: "account" }],
    },
    account: {
      type: "custom",
      render: ({ onChange }) => <SelectAccountId onSelectAccount={onChange} />,
    },
  },
  defaultProps: {
    source: "account",
  },
  render: ({ account }: GiftCardsProps) => {
    return <GiftCardsDisplay account_id={account} />;
  },
};
