//./components/Autocomplete.tsx

import React, { memo, useRef } from "react";
import { MultiSearchResult } from "meilisearch";

type Props = {
  items: MultiSearchResult<any>[];
  onChange: (val: string) => void;
  onSelect: (val: any) => void;
  searchResultItemsRenderer?: (
    items: MultiSearchResult<any>[],
  ) => React.ReactNode;
  placeholder?: string;
};

//we are using dropdown, input and menu component from daisyui
const Autocomplete = ({
  searchResultItemsRenderer,
  items,
  onChange,
  onSelect,
  placeholder,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={"flex flex-col"}>
      <input
        type="text"
        className="input input-bordered w-full"
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder ? placeholder : "Search for events"}
        tabIndex={0}
      />
      <div
        // use classnames here to easily toggle dropdown open
        className={`dropdown w-full z-10 ${items.length !== 0 ? "dropdown-open" : ""}`}
        ref={ref}
      >
        {items && items.length !== 0 && (
          <div className="dropdown-content bg-white border-solid border-l-2 border-r-2 border-b-2 border-t-0 jc-border-gray-600 rounded max-h-96 overflow-auto flex-col">
            <ul
              className="menu menu-compact "
              // use ref to calculate the width of parent
              style={{ width: ref.current?.clientWidth }}
            >
              {searchResultItemsRenderer
                ? searchResultItemsRenderer(items)
                : items
                    .flatMap((i) => i.hits)
                    .map((item, index) => {
                      return (
                        <li key={index} tabIndex={index + 1} className="w-full">
                          <button
                            onClick={() => {
                              onSelect(item);
                            }}
                          >
                            <div className={"flex flex-col"}>
                              <div>{item.title}</div>
                              <div className={"text-xs text-gray-500"}>
                                {item.dates}
                              </div>
                            </div>
                          </button>
                        </li>
                      );
                    })}
            </ul>
            {/* add this part */}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Autocomplete);
