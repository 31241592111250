import React from "react";
import { GiftCardList } from "@components/gift-card-list";
import useGiftCards from "@hooks/gift-cards";

export default function GiftCardsDisplay({
  account_id,
}: {
  account_id?: string;
}) {
  const { gift_cards } = useGiftCards({
    account_id,
  });

  if (!gift_cards) return <div>Loading...</div>;
  return (
    <div>
      <GiftCardList products={gift_cards} />
    </div>
  );
}
