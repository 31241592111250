import React from "react";

import { ComponentConfig } from "@measured/puck";
import { Product } from "@models/models";
import product from "@components/puck/page-builder/fields/digital-product";
import ProductDisplay from "@components/product-display";

export type DigitalProductProps = {
  product?: Product;
};

export const DigitalProductComponent: ComponentConfig<DigitalProductProps> = {
  label: "Digital Product",
  fields: {
    product,
  },

  render: ({ product }: DigitalProductProps) => {
    if (!product)
      return (
        <div className={"p-2 text-center w-full border b-1"}>
          Select a product from the sidebar
        </div>
      );
    return <ProductDisplay product={product} />;
  },
};
