import { selectAccount } from "@store/account-slice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function SelectAccountId({
  onSelectAccount,
}: {
  onSelectAccount: (value: string) => void;
}) {
  const account = useSelector(selectAccount);
  useEffect(() => {
    if (!account) return;
    onSelectAccount(account.id);
  }, [account]);

  return <div></div>;
}
