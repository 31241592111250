import { useGetGiftCardsQuery } from "@store/adsSlice";

type GiftCardsProps = {
  account_id?: string;
};
export default function useGiftCards(props: GiftCardsProps) {
  const { data: gift_cards } = useGetGiftCardsQuery({
    ...props,
  });

  return { gift_cards };
}
