"use client";
import { useStoreUser } from "@hooks/store-user";
import {
  useFollowVenueMutation,
  useUnfollowVenueMutation,
} from "@store/apiSlice";
import { useAppDispatch } from "@store/store";
import { setCustomer } from "@store/user-slice";
import Link from "next/link";
import * as React from "react";
import { Customer } from "@models/models";
import { usePathname } from "next/navigation";
import { useErrorHandler } from "@hooks/error-handler";

const isFollowingVenue = (customer: Customer, venueId: string) => {
  if (!customer) return false;
  return (
    customer.venueFollows.find((vf) => vf.venue.id === venueId) !== undefined
  );
};

export function FollowVenueButton({ venueId }: { venueId: string }) {
  const { customer } = useStoreUser();
  const pathname = usePathname();

  const [followVenue] = useFollowVenueMutation();
  const [unfollowVenue] = useUnfollowVenueMutation();
  const dispatch = useAppDispatch();

  const follow = useErrorHandler(async (venueId: string) => {
    const customer = await followVenue(venueId).unwrap();
    dispatch(setCustomer(customer));
  });

  const unfollow = useErrorHandler(async (venueId: string) => {
    const customer = await unfollowVenue(venueId).unwrap();
    dispatch(setCustomer(customer));
  });

  return (
    <div>
      {customer && isFollowingVenue(customer, venueId) ? (
        <button
          className={"btn btn-accent btn-md"}
          onClick={(e) => unfollow(venueId)}
        >
          Following
        </button>
      ) : customer ? (
        <button
          className={"btn btn-accent btn-md"}
          onClick={() => follow(venueId)}
        >
          Notify Me for New Shows
        </button>
      ) : (
        <Link
          href={`${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/mct/login/customer?returnTo=${window.location.href}`}
          className={"btn btn-accent btn-md"}
        >
          Notify Me for New Shows
        </Link>
      )}
    </div>
  );
}
