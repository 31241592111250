export type SpinWheelColors = "green" | "blue" | "yellow" | "red";
export default {
  green: [
    "#008000",
    "#228B22",
    "#006400",
    "#2E8B57",
    "#3CB371",
    "#20B2AA",
    "#98FB98",
    "#00FF7F",
    "#00FA9A",
    "#32CD32",
    "#00FF00",
    "#9ACD32",
    "#6B8E23",
    "#808000",
    "#556B2F",
    "#7FFF00",
    "#7CFC00",
    "#ADFF2F",
    "#8FBC8F",
    "#90EE90",
    "#66CDAA",
    "#BDB76B",
    "#F0E68C",
    "#F5FFFA",
    "#F0FFF0",
  ],
  blue: [
    "#0000FF",
    "#0000CD",
    "#00008B",
    "#000080",
    "#191970",
    "#4169E1",
    "#4682B4",
    "#1E90FF",
    "#6495ED",
    "#00BFFF",
    "#87CEEB",
    "#87CEFA",
    "#ADD8E6",
    "#B0C4DE",
    "#B0E0E6",
    "#AFEEEE",
    "#E0FFFF",
    "#00FFFF",
    "#5F9EA0",
    "#48D1CC",
    "#40E0D0",
    "#00CED1",
    "#20B2AA",
    "#008B8B",
    "#008080",
  ],
  yellow: [
    "#FFFF00",
    "#FFD700",
    "#FFA500",
    "#FF8C00",
    "#FFD700",
    "#FFE135",
    "#FFFACD",
    "#FAFAD2",
    "#FFEFD5",
    "#FFE4B5",
    "#FFDAB9",
    "#EEE8AA",
    "#F0E68C",
    "#BDB76B",
    "#F5DEB3",
    "#FFEBCD",
    "#FFF8DC",
    "#FFFAF0",
    "#FFF5EE",
    "#FFF0F5",
    "#FAEBD7",
    "#F5F5DC",
    "#FFFFE0",
    "#FFFFF0",
    "#FFFACD",
  ],
  red: [
    "#FF0000",
    "#FF6347",
    "#FF4500",
    "#DC143C",
    "#B22222",
    "#8B0000",
    "#FF7F50",
    "#FF3030",
    "#FF69B4",
    "#FF1493",
    "#DB7093",
    "#C71585",
    "#FFB6C1",
    "#FFB6C1",
    "#FF6347",
    "#FF4500",
    "#FF3030",
    "#FF0000",
    "#8B0000",
    "#A52A2A",
    "#B22222",
    "#800000",
    "#B03060",
    "#D02090",
    "#FF00FF",
  ],
};
