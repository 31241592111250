import { Contest } from "@models/models";
import { amplifyGet } from "@utils/http";
import { Field } from "@measured/puck";

const field: Field<Contest | undefined> = {
  type: "external",
  label: "Contest",
  showSearch: true,
  getItemSummary: (item: Contest) => item.name,
  mapRow: (item: Contest) => ({
    Name: item.name,
  }),
  fetchList: async ({ query }) => {
    const result = await amplifyGet("/api/contests", { with_account_id: true });
    const contests = result.data as Contest[];
    return !query
      ? contests
      : contests.filter((p) => p.name.toLowerCase().includes(query));
  },
};

export default field;
