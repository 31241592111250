import React from "react";

import { ComponentConfig } from "@measured/puck";
import SelectAccountId from "@components/puck/page-builder/components/select-account-id";
import PassesDisplay from "@components/puck/page-builder/data-components/passes-display";

export type PassesProps = {
  account?: string;
  source: "account";
};

export const Passes: ComponentConfig<PassesProps> = {
  label: "Passes",
  fields: {
    source: {
      type: "select",
      label: "Source",
      options: [{ label: "Account", value: "account" }],
    },
    account: {
      type: "custom",
      render: ({ onChange }) => <SelectAccountId onSelectAccount={onChange} />,
    },
  },
  defaultProps: {
    source: "account",
  },
  render: ({ account }: PassesProps) => {
    return <PassesDisplay account_id={account} />;
  },
};
