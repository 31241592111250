import React from "react";
import { useField } from "formik";

export const CheckboxInput = ({
  label,
  required,
  labelClassNames,
  ...props
}: any) => {
  const [field] = useField(props);
  return (
    <>
      {label && (
        <label className="label cursor-pointer">
          <div className={"flex gap-x-2 items-center"}>
            <input
              className={"checkbox"}
              type={"checkbox"}
              {...field}
              {...props}
            />{" "}
            <span
              className={`label-text ${labelClassNames ? labelClassNames : ""}`}
            >
              {label}{" "}
              {required ? (
                <span>
                  <sup className={"text-red-600 font-bold"}>*</sup>
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
        </label>
      )}
    </>
  );
};
