"use client";
import { memo, useCallback, useState } from "react";
import { Hit, MeiliSearch, MultiSearchResult } from "meilisearch";
import { useRouter } from "next/navigation";
import Autocomplete from "./auto-complete";
const client = new MeiliSearch({
  host: process.env.NEXT_PUBLIC_MEILISEARCH_HOST as string,
  apiKey: process.env.NEXT_PUBLIC_MEILISEARCH_API_KEY as string,
});

type SearchBoxProps = {
  indexes: string[];
  getSelectableUrl?: (result: any) => string;
  filter?: string;
  onSelect?: (result: any) => void;
  searchResultItemsRenderer?: (
    items: MultiSearchResult<any>[],
  ) => React.ReactNode;
  placeholder?: string;
};

function SearchBox({
  searchResultItemsRenderer,
  onSelect,
  indexes,
  getSelectableUrl,
  filter,
  placeholder,
}: SearchBoxProps) {
  const router = useRouter();
  const [searchResults, setSearchResults] = useState<
    MultiSearchResult<Record<string, any>>[]
  >([]);
  const navigateToSearchResultItem = useCallback(
    (selected: Hit<Record<string, any>>) => {
      if (getSelectableUrl) {
        router.push(getSelectableUrl(selected));
      } else {
        router.push(selected.relativePermalink);
      }
    },
    [router, getSelectableUrl],
  );
  const updateSearchResults = useCallback(async (v: string) => {
    const minCharsBeforeSearching = 3;
    if (v && v.length >= minCharsBeforeSearching) {
      const result = await client.multiSearch({
        queries: indexes.map((index) => ({
          q: v,
          indexUid: index,
          filter,
        })),
      });
      setSearchResults(result.results);
    } else {
      setSearchResults([]);
    }
  }, []);

  return (
    <div className="container mx-auto max-w-xl">
      <div className={"mx-1"}>
        <Autocomplete
          placeholder={placeholder}
          searchResultItemsRenderer={searchResultItemsRenderer}
          onSelect={onSelect ? onSelect : navigateToSearchResultItem}
          onChange={updateSearchResults}
          items={searchResults}
        />
      </div>
    </div>
  );
}

export default memo(SearchBox);
