import { useEffect, useState } from "react";
import { WheelData } from "react-custom-roulette/dist/components/Wheel/types";
import dynamic from "next/dynamic";
import { Prize } from "@models/models";
import { getSpinResultIndex } from "@utils/spin-wheel-prize-picker";
import spinWheelColors, { SpinWheelColors } from "@utils/spin-wheel-colors";
const WHEEL_SLICES = 6;
const allOptions = () => [
  { option: "", style: { backgroundColor: "#abcdee" } },
  { option: "", style: { backgroundColor: "#abcdef" } },
  { option: "", style: { backgroundColor: "#ace5ee" } },
  { option: "", style: { backgroundColor: "#b0e0e6" } },
  { option: "", style: { backgroundColor: "#a1caf1" } },
  { option: "", style: { backgroundColor: "#a4dded" } },
];

function getRandomUniqueIntegers(
  total_count: number,
  pick_count: number,
): number[] {
  if (pick_count > total_count) {
    throw new Error("pick_count cannot be greater than total_count");
  }

  const uniqueNumbers = new Set<number>();

  while (uniqueNumbers.size < pick_count) {
    const randomNum = Math.floor(Math.random() * (total_count + 1));
    uniqueNumbers.add(randomNum);
  }

  return Array.from(uniqueNumbers);
}

function generateData(prizes: Prize[], base_color: SpinWheelColors | null) {
  let lo;
  if (!base_color) {
    lo = allOptions();
  } else {
    lo = spinWheelColors[base_color].map((color) => ({
      option: "",
      style: { backgroundColor: color },
    }));
  }
  let data = [];
  for (let i = 0; i < WHEEL_SLICES; i++) {
    data.push({
      ...lo.shift(),
    });
  }
  const winning_indexes = getRandomUniqueIntegers(
    WHEEL_SLICES - 1,
    prizes.length,
  );
  let prize_label_index = 0;
  for (let i = 0; i < winning_indexes.length; i++) {
    data[winning_indexes[i]].option = prizes[prize_label_index].name;
    prize_label_index++;
  }

  return data;
}

const Wheel = dynamic(() =>
  import("react-custom-roulette").then((mod) => mod.Wheel),
);

export default function SpinWheel({
  winning_prize_id,
  onGameComplete,
  config,
  prizes,
}: {
  winning_prize_id?: number;
  config: Record<string, any>;
  onGameComplete: () => void;
  prizes: Prize[];
}) {
  const [mustSpin, setMustSpin] = useState(false);
  const [data, setData] = useState<WheelData[]>([]);
  const [spinResultIndex, setSpinResultIndex] = useState<number>();

  useEffect(() => {
    const gen_data = generateData(
      prizes,
      config.fields?.base_color?.value ? config.fields.base_color.value : null,
    );

    setSpinResultIndex(
      getSpinResultIndex(structuredClone(gen_data), winning_prize_id, prizes),
    );
    const losing_labels = config.fields?.losing_labels?.value
      ? config.fields.losing_labels.value
      : null;
    if (losing_labels !== null) {
      const losing_labels_options = losing_labels.split(/\n/);
      let losing_labels_index = 0;
      for (let i = 0; i < gen_data.length; i++) {
        if (
          gen_data[i].option === "" &&
          losing_labels_options.length - 1 >= losing_labels_index
        ) {
          gen_data[i].option = losing_labels_options[losing_labels_index];
          losing_labels_index++;
        }
      }
    }
    setData(gen_data);
  }, []);
  if (!data || data.length === 0 || spinResultIndex === undefined)
    return <div>Loading...</div>;
  return (
    <div className={"flex flex-col gap-8 items-center"}>
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={spinResultIndex}
        data={data}
        onStopSpinning={() => {
          onGameComplete();
          setMustSpin(false);
        }}
      />
      <div>
        {!mustSpin && (
          <button
            className={`btn btn-primary`}
            onClick={() => setMustSpin(true)}
          >
            Spin
          </button>
        )}
      </div>
    </div>
  );
}
