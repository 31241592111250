import useEvents from "@hooks/events";
import React, { useEffect } from "react";
import { EventList } from "@components/event-list";
import { EventDisplayMode } from "@models/models";

export default function EventDisplay({
  account_id,
  platform_id,
  event_count,
  slug,
  show_calendar_option,
  default_display_view,
}: {
  account_id?: string;
  platform_id?: number;
  slug?: string;
  event_count: number;
  show_calendar_option: boolean;
  default_display_view: EventDisplayMode;
}) {
  const { events, refetch } = useEvents({
    platform_id,
    account_id,
    slug,
    type: "upcoming",
  });

  useEffect(() => {
    refetch();
  }, [event_count, account_id, slug]);

  if (!events) return <div>Loading...</div>;
  return (
    <div>
      <EventList
        eventDisplayMode={default_display_view}
        showEventDisplayOptions={show_calendar_option}
        products={events}
      />
    </div>
  );
}
