"use client";
import React, { useEffect, useState } from "react";
import { CompactPicker } from "react-color";

type ColorPickerProps = {
  label?: string;
  value?: string;
  onChange: (value: string) => void;
};

export default function ColorPicker({
  label,
  value,
  onChange,
}: ColorPickerProps) {
  const [hex, setHex] = useState(value);
  useEffect(() => {
    if (!value) {
      setHex("#ffffff");
    }
  }, [value]);
  if (!hex) return <div>Loading...</div>;
  return (
    <>
      {label && (
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <CompactPicker
        color={hex}
        onChange={(color) => {
          setHex(color.hex);
          onChange(color.hex);
        }}
      />
    </>
  );
}
