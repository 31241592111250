export type ContainerOptions = {
  margin_top: string;
  margin_right: string;
  margin_bottom: string;
  margin_left: string;
  padding_top: string;
  padding_right: string;
  padding_bottom: string;
  padding_left: string;
  background_color: string;
  border_width_top: string;
  border_width_right: string;
  border_width_bottom: string;
  border_width_left: string;
  border_color: string;
  border_radius: string;
};

export const containerDefaults = {
  padding_top: "0",
  padding_right: "0",
  padding_bottom: "0",
  padding_left: "0",
  border_width_top: "0",
  border_width_right: "0",
  border_width_bottom: "0",
  border_width_left: "0",
  margin_top: "0",
  margin_right: "0",
  margin_bottom: "0",
  margin_left: "0",
  background_color: "#ffffff",
  border_color: "#ffffff",
  border_radius: "5",
};

export function spacingStyleProps(props: ContainerOptions) {
  if (!props) return {};
  return {
    paddingTop: toPxOrUndefined(props.padding_top),
    paddingRight: toPxOrUndefined(props.padding_right),
    paddingBottom: toPxOrUndefined(props.padding_bottom),
    paddingLeft: toPxOrUndefined(props.padding_left),
    marginTop: toPxOrUndefined(props.margin_top),
    marginRight: toPxOrUndefined(props.margin_right),
    marginBottom: toPxOrUndefined(props.margin_bottom),
    marginLeft: toPxOrUndefined(props.margin_left),
    borderTopWidth: toPxOrUndefined(props.border_width_top),
    borderRightWidth: toPxOrUndefined(props.border_width_right),
    borderBottomWidth: toPxOrUndefined(props.border_width_bottom),
    borderLeftWidth: toPxOrUndefined(props.border_width_left),
    backgroundColor: toPxOrUndefined(props.background_color),
    borderColor: props.border_color ? props.border_color : undefined,
    borderRadius: toPxOrUndefined(props.border_radius),
    borderStyle: "solid",
  };
}

function toPxOrUndefined(value: any) {
  return value ? `${value}px` : undefined;
}
