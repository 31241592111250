export default function EmptyListState({
  list,
  message,
}: {
  list: any[] | undefined;
  message: string;
}) {
  return (
    <div className={"mt-4 text-gray-500"}>
      {list && list.length === 0 && message}
    </div>
  );
}
