import { ContentBlock } from "../../models/models";
import Image from "next/image";
import Link from "next/link";
export default function HighlightLink({
  contentBlock,
}: {
  contentBlock: ContentBlock;
}) {
  return (
    <div className={"flex flex-col gap-y-1 max-w-12"}>
      <div className="avatar">
        <div className="w-12 rounded-full">
          <Link href={contentBlock.url}>
            <Image
              alt={contentBlock.title}
              src={
                contentBlock.thumbnail
                  ? contentBlock.thumbnail
                  : "https://i.imgur.com/sFwgEOO.png"
              }
              height={100}
              width={100}
            />
          </Link>
        </div>
      </div>
      <div className={"text-xs text-center"}>{contentBlock.title}</div>
    </div>
  );
}
