import { useEffect, useState } from "react";

export type PaginationInput = {
  limit: number;
  offset: number;
};

export const defaultPaginationInput: PaginationInput = {
  limit: 10,
  offset: 0,
};

type UsePaginationProps = {
  itemsPerPage?: number;
  loadItems: Function;
  dependencies?: any[];
};
export function usePagination({
  itemsPerPage = defaultPaginationInput.limit,
  loadItems,
  dependencies = [],
}: UsePaginationProps) {
  const [pageCount, setPageCount] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [paginationParams, setPaginationParams] = useState(
    defaultPaginationInput,
  );

  const getNewOffset = (selected: number) => {
    return (selected * itemsPerPage) % totalItemCount;
  };

  useEffect(() => {
    loadItems(paginationParams);
  }, [paginationParams].concat(dependencies));

  const initialize = (totalItemCount: number) => {
    setTotalItemCount(totalItemCount);
    setPageCount(Math.ceil(totalItemCount / itemsPerPage));
  };

  const handlePageClick = async (event: { selected: number }) => {
    const newOffset = getNewOffset(event.selected as number);
    setPaginationParams((params) => {
      return { ...paginationParams, offset: newOffset };
    });
  };

  return {
    handlePageChange: handlePageClick,
    initialize,
    pageCount,
    paginationParams,
  };
}
