import React from "react";
const md5 = require("md5");
import { ComponentConfig } from "@measured/puck";
import SearchBox from "@components/search-box";

export type SearchProps = {
  account_id: string;
};

export const Search: ComponentConfig<SearchProps> = {
  label: "Search",

  resolveFields: async (data) => {
    const account_id = localStorage.getItem("accountId");
    return {
      account_id: {
        type: "radio",
        label: "What events to include?",
        options: [
          { label: "All Jump Events", value: "" },
          { label: "My Events Only", value: account_id ? account_id : "" },
        ],
      },
    };
  },
  defaultProps: {
    account_id: "",
  },

  render: ({ account_id }: SearchProps) => {
    if (account_id) {
      return (
        <SearchBox
          indexes={["products"]}
          filter={`accountIdHash = ${md5(account_id)}`}
        />
      );
    } else {
      return <SearchBox indexes={["products"]} />;
    }
  },
};
