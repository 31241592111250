"use client";
import React, { useState } from "react";
import { EventDisplayMode, Product } from "@models/models";
import CalendarEvents from "./calendar-events";
import { convertProductsToCalendarEvents } from "@utils/convert-products-to-calendar-events";
import { isMobile } from "react-device-detect";
import EventThumbnailList from "@components/event-thumbnail-list";

type EventListProps = {
  products: Product[];
  showPast?: boolean;
  id?: string;
  name?: string;
  showEventDisplayOptions?: boolean;
  eventDisplayMode?: EventDisplayMode;
};
export function EventList({
  products,
  eventDisplayMode,
  showEventDisplayOptions,
}: EventListProps) {
  const [displayMode, setDisplayMode] = useState(
    eventDisplayMode ? eventDisplayMode : EventDisplayMode.LIST,
  );
  if (!products) return <div>Loading...</div>;
  return (
    <div className={"flex flex-col"}>
      {showEventDisplayOptions && (
        <div className={"flex w-full place-content-center my-4"}>
          <div className="join join-vertical lg:join-horizontal">
            <button
              type="button"
              onClick={() => setDisplayMode(EventDisplayMode.LIST)}
              className={`btn btn-sm hover:bg-black ${displayMode === EventDisplayMode.LIST ? "bg-jump" : "bg-gray-400"} text-white join-item`}
            >
              View Images
            </button>
            <button
              type="button"
              onClick={() => setDisplayMode(EventDisplayMode.CALENDAR)}
              className={`btn btn-sm hover:bg-black ${displayMode === EventDisplayMode.CALENDAR ? "bg-jump" : "bg-gray-400"} text-white join-item`}
            >
              View Calendar
            </button>
          </div>
        </div>
      )}
      {displayMode === EventDisplayMode.CALENDAR && (
        <CalendarEvents
          events={convertProductsToCalendarEvents(products, isMobile)}
          constructOnSelectUrl={(e) => `/e/${e.handle}`}
        />
      )}
      {displayMode === EventDisplayMode.LIST && (
        <EventThumbnailList products={products} />
      )}
    </div>
  );
}
