import React from "react";

import { ComponentConfig } from "@measured/puck";
import { Contest } from "@models/models";
import PlayContestComponent from "@components/play-contest";
import contest from "@components/puck/page-builder/fields/contest";

export type PlayContestProps = {
  contest?: Contest;
};

export const PlayContest: ComponentConfig<PlayContestProps> = {
  label: "Play Contest",
  fields: {
    contest,
  },

  render: ({ contest }: PlayContestProps) => {
    if (!contest)
      return (
        <div className={"p-2 text-center w-full border b-1"}>
          Select a contest from the sidebar
        </div>
      );
    return (
      <div>
        <PlayContestComponent contestKey={contest.key} />
      </div>
    );
  },
};
