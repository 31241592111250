import React from "react";

import { ComponentConfig } from "@measured/puck";

export type VerticalSpaceProps = {
  size: string;
};

export const VerticalSpace: ComponentConfig<VerticalSpaceProps> = {
  label: "Vertical Space",
  fields: {
    size: {
      type: "select",
      options: [
        { label: "8px", value: "8px" },
        { label: "16px", value: "16px" },
        { label: "24px", value: "24px" },
        { label: "32px", value: "32px" },
        { label: "40px", value: "40px" },
        { label: "48px", value: "48px" },
        { label: "56px", value: "56px" },
        { label: "64px", value: "64px" },
        { label: "72px", value: "72px" },
      ],
    },
  },
  defaultProps: {
    size: "24px",
  },
  render: ({ size }: { size: string }) => {
    return <div style={{ height: size, width: "100%" }} />;
  },
};
