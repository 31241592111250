"use client";
import { Contest, ContestEntry } from "@models/models";
import { useState } from "react";
import SpinWheel from "./spin-wheel";
import { useErrorHandler } from "@hooks/error-handler";
import { useCompleteContestEntryMutation } from "@store/adsSlice";
import dayjs from "dayjs";
import { Message } from "@components/message";

import Image from "next/image";

export default function ContestGames({
  contestEntry,
  contest,
}: {
  contestEntry: ContestEntry;
  contest: Contest;
}) {
  const [completeContestEntry] = useCompleteContestEntryMutation();
  const [showResults, setShowResults] = useState(false);
  const isWinner = useErrorHandler(async () => {
    await completeContestEntry(contestEntry.id).unwrap();
    setShowResults(true);
  });
  return (
    <>
      {!showResults && (
        <SpinWheel
          prizes={contest.prizes}
          config={contest.game_config}
          winning_prize_id={contestEntry.prize?.id}
          onGameComplete={isWinner}
        />
      )}
      {showResults && (
        <div>
          {contestEntry.prize && (
            <div className={"text-lg mt-4"}>
              <div className={"flex flex-col gap-4 items-center"}>
                {contestEntry.prize.thumbnail && (
                  <Image
                    alt={contestEntry.prize.name}
                    width={400}
                    height={400}
                    src={contestEntry.prize.thumbnail}
                  />
                )}
                <div className={"badge badge-primary p-4"}>
                  Date Won: {dayjs().format("YYYY-MM-DD HH:mm:ss A")}
                </div>
                <Message
                  message={contestEntry.prize.winner_message}
                  title={"Congrats!"}
                  type={"success"}
                />
              </div>
            </div>
          )}
          {!contestEntry.prize && contest.lost_image && (
            <Image
              src={contest.lost_image}
              height={400}
              width={400}
              alt={"Lost Image"}
            />
          )}
          {contestEntry.subscribed && !contestEntry.prize && (
            <div className={"text-lg mt-4"}>{contest.subscribed_lost_text}</div>
          )}
          {!contestEntry.subscribed && !contestEntry.prize && (
            <div className={"text-lg mt-4"}>
              {contest.not_subscribed_lost_text}
            </div>
          )}
        </div>
      )}
    </>
  );
}
