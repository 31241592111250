import Avatar from "@components/linktree/avatar";
import Name from "@components/linktree/name";
import Highlights from "@components/linktree/highlights";
import React from "react";
import { Participant, ParticipantContentBlock } from "@models/models";

export default function ParticipantAvatarAndHighlights({
  participant,
  participantContentBlocks,
}: {
  participant: Participant;
  participantContentBlocks: ParticipantContentBlock[];
}) {
  return (
    <div className={"flex gap-x-4 w-full p-4"}>
      <div className={"flex gap-6 items-center  place-content-center w-full"}>
        <div>
          <Avatar participant={participant} />
        </div>
        <div className={"flex flex-col gap-y-4 items-center"}>
          <Name participant={participant} />
          <Highlights participantContentBlocks={participantContentBlocks} />
        </div>
      </div>
    </div>
  );
}
