import ParticipantAvatarAndHighlights from "@components/linktree/participant-avatar-and-highlights";
import { Participant } from "@models/models";
import { useGetSlugEntityBySlugQuery } from "@store/adsSlice";

export default function ComicProfileDisplay({ slug }: { slug: string }) {
  const { data: participant } = useGetSlugEntityBySlugQuery(slug);
  if (!participant) return <div>Loading...</div>;
  return (
    <div>
      <ParticipantAvatarAndHighlights
        participant={participant as Participant}
        participantContentBlocks={(participant as Participant).contentBlocks}
      />
    </div>
  );
}
