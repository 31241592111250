import React from "react";

export default function SmallInput({
  value,
  name,
  onChange,
  label,
}: {
  value: string;
  label: string;
  name: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className={"flex flex-col gap-y-1"}>
      <div className={"text-gray-500 text-xs"}>{label}</div>
      <input
        type={"text"}
        className={"w-12 border b-1 text-center text-xs"}
        defaultValue={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}
