import React from "react";
import { ComponentConfig, DropZone } from "@measured/puck";
import {
  containerDefaults,
  ContainerOptions,
  spacingStyleProps,
} from "@components/puck/page-builder/formatting/container-options";
import container from "@components/puck/page-builder/fields/container";

export type ContainerProps = {
  container_options: ContainerOptions;
};

export const Container: ComponentConfig<ContainerProps> = {
  label: "Container",
  fields: {
    container_options: container,
  },
  defaultProps: {
    container_options: containerDefaults,
  },
  render: ({ container_options }) => {
    return (
      <div style={{ ...spacingStyleProps(container_options) }}>
        <DropZone
          style={{
            minHeight: "150px",
            minWidth: "150px",
            borderStyle: "dotted",
            borderColor: "#ccc",
            margin: "10px",
            borderWidth: "1px",
          }}
          zone={`container}`}
        />
      </div>
    );
  },
};
