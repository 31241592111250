import React from "react";

import { ComponentConfig } from "@measured/puck";
import ImageUploaderModal from "@components/image-uploader-modal";
import { default as NextImage } from "next/image";

export type ImageProps = {
  url: string;
  width: number;
  align: "left" | "center" | "right";
};

export const WebImage: ComponentConfig<ImageProps> = {
  label: "Image",
  fields: {
    url: {
      type: "custom",
      render: ({ name, onChange, value }) => (
        <ImageUploaderModal
          onImageSelect={(payload: { url: string }) => {
            onChange({ url: payload.url } as any);
          }}
        />
      ),
    },
    width: {
      type: "text",
      label: "Width in pixels",
    },
    align: {
      type: "radio",
      options: [
        { label: "Left", value: "justify-start" },
        { label: "Center", value: "justify-center" },
        { label: "Right", value: "justify-end" },
      ],
    },
  },
  defaultProps: {
    align: "center",
    url: "",
    width: 200,
  },

  render: ({ url, height, width, align }: any) => {
    return (
      <>
        {url?.url ? (
          <div className={`flex ${align}`}>
            <div>
              <NextImage
                alt={"Logo"}
                src={url.url}
                width={0}
                height={0}
                sizes={`${width}vw`}
                style={{
                  maxHeight: "1000px",
                  width: `${width}px`,
                  height: "auto",
                }}
              />
            </div>
          </div>
        ) : (
          <div className={"p-2 text-center w-full border b-1"}>
            Select Image from Sidebar
          </div>
        )}
      </>
    );
  },
};
