import HighlightLink from "./highlight-link";
import React from "react";
import {
  ContentBlockDisplayFormat,
  ParticipantContentBlock,
} from "../../models/models";

function highlights(participantContentBlocks: ParticipantContentBlock[]) {
  return participantContentBlocks.filter(
    (pcb) =>
      pcb.contentBlock.enabled &&
      pcb.contentBlock.displayFormat === ContentBlockDisplayFormat.HIGHLIGHT,
  );
}
export default function Highlights({
  participantContentBlocks,
}: {
  participantContentBlocks: ParticipantContentBlock[];
}) {
  return (
    <>
      {" "}
      {highlights(participantContentBlocks).length !== 0 && (
        <div className={"flex gap-x-5 flex-wrap"}>
          {highlights(participantContentBlocks).map((pcb) => (
            <div key={`mockup-${pcb.id}`}>
              <HighlightLink contentBlock={pcb.contentBlock} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}
