import { EventListFilterTypes, Product } from "../models/models";
import { useEffect, useState } from "react";
import { defaultPaginationInput } from "./pagination";
import { useLazyGetProductsToManageQuery } from "../store/apiSlice";
import { useSelector } from "react-redux";
import { selectAccount } from "../store/account-slice";

export default function useProductsToManage(
  filterType: EventListFilterTypes,
  limit?: number,
) {
  const [getProducts] = useLazyGetProductsToManageQuery();
  const account = useSelector(selectAccount);
  const [products, setProducts] = useState<Product[]>();

  useEffect(() => {
    if (!account) return;
    let pagination = defaultPaginationInput;
    if (limit) {
      pagination = { ...defaultPaginationInput, limit };
    }
    getProducts({ ...pagination, filterType, accountId: account.id }).then(
      (result) => {
        setProducts(result.data?.items);
      },
    );
  }, [account]);

  return products;
}
