import React from "react";

import { ComponentConfig, FieldLabel } from "@measured/puck";
import VariantSelector, {
  SelectedVariantInfo,
} from "@components/variant-selector";
import RedeemTicket from "@components/redeem-ticket";
import {
  containerDefaults,
  ContainerOptions,
  spacingStyleProps,
} from "@components/puck/page-builder/formatting/container-options";
import container from "@components/puck/page-builder/fields/container";

export type RedeemTicketsProps = {
  button_label: string;
  success_message: string;
  intro_text: string;
  variant_id: string;
  container_options: ContainerOptions;
};

export const RedeemTickets: ComponentConfig<RedeemTicketsProps> = {
  label: "Redeem Tickets",
  fields: {
    button_label: {
      label: "Submit Button Label",
      type: "text",
    },
    intro_text: {
      label: "Intro Text",
      type: "textarea",
    },
    success_message: {
      label: "Success Message",
      type: "textarea",
    },
    variant_id: {
      type: "custom",
      render: ({ onChange }) => {
        return (
          <div>
            <FieldLabel label={"Event Information"} />
            <VariantSelector
              isMulti={false}
              onChange={(variant_info) => {
                onChange((variant_info as SelectedVariantInfo).id);
              }}
            />
          </div>
        );
      },
    },
    container_options: container,
  },
  defaultProps: {
    variant_id: "",
    intro_text: "Please enter the code from your ticket.",
    success_message: "You have successfully redeemed you code!",
    button_label: "Redeem Code",
    container_options: containerDefaults,
  },

  render: ({
    button_label,
    success_message,
    variant_id,
    intro_text,
    container_options,
  }: RedeemTicketsProps) => {
    return (
      <div style={{ ...spacingStyleProps(container_options) }}>
        <RedeemTicket
          intro_text={intro_text}
          button_label={button_label}
          success_message={success_message}
          variant_id={variant_id}
        />
      </div>
    );
  },
};
