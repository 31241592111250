import React from "react";

import { ComponentConfig } from "@measured/puck";
import { FollowVenueButton } from "@components/follow-venue-button";
import { Venue } from "@models/models";
import venue from "@components/puck/page-builder/fields/venue";

export type NotifyVenueProps = {
  venue?: Venue;
};

export const NotifyVenue: ComponentConfig<NotifyVenueProps> = {
  label: "Get Show Alerts for Venue",
  fields: {
    venue,
  },

  render: ({ venue }: NotifyVenueProps) => {
    if (!venue)
      return (
        <div className={"p-2 text-center w-full border b-1"}>
          Select a venue from the sidebar
        </div>
      );
    return (
      <div>
        <FollowVenueButton venueId={venue.id} />
      </div>
    );
  },
};
