import React, { useState } from "react";
import ImageUploader from "@components/image-uploader";

export default function ImageUploaderModal({
  onImageSelect,
}: {
  onImageSelect: (payload: { url: string }) => void;
}) {
  const [showImageUploader, setShowImageUploader] = useState(false);

  return (
    <>
      {!showImageUploader && (
        <button
          className={"btn btn-primary"}
          onClick={() => setShowImageUploader(true)}
        >
          Select Image
        </button>
      )}
      <>
        {showImageUploader && (
          <dialog
            id="my_modal_1"
            className={`modal ${showImageUploader ? "modal-open" : ""}`}
          >
            <div className="modal-box">
              <h3 className="font-bold text-lg mb-2">Upload Image!</h3>
              <ImageUploader
                onSelect={onImageSelect}
                onClose={() => setShowImageUploader(false)}
              />
              <div className="modal-action">
                {/* if there is a button in form, it will close the modal */}
                <button
                  className="btn"
                  onClick={() => setShowImageUploader(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </dialog>
        )}
      </>
    </>
  );
}
