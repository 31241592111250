"use client";
import { Form, Formik } from "formik";
import { TextInput } from "./forms/text-input";
import { CheckboxInput } from "./forms/checkbox-input";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { ContestEntry } from "@models/models";
import useLocationInfo from "../hooks/location-info";
import {
  CreateContestEntryRequest,
  useCreateContestEntryMutation,
  useGetContestByKeyQuery,
} from "@store/adsSlice";
import { useErrorHandler } from "@hooks/error-handler";
import ContestGames from "@components/contest-games";
import Image from "next/image";
import { useDocumentTitle } from "usehooks-ts";

export default function PlayContest({ contestKey }: { contestKey: string }) {
  const [showTos, setShowTos] = useState(false);
  const [createContestEntry] = useCreateContestEntryMutation();
  const { data: contest } = useGetContestByKeyQuery(contestKey);
  const [title, setTitle] = useState("");
  const [contestEntry, setContestEntry] = useState<ContestEntry>();
  const locationInfo = useLocationInfo();
  const submitForm = useErrorHandler(
    async (values: CreateContestEntryRequest) => {
      if (!values.termsOfService) {
        throw new Error(
          "You must accept the terms of game play by checking the checkbox.",
        );
      }
      values.location_info = locationInfo;
      const contestEntry = await createContestEntry(values).unwrap();
      setContestEntry(contestEntry);
    },
  );
  useDocumentTitle(title);
  useEffect(() => {
    if (contest) {
      setTitle(contest.name);
    }
  }, [contest]);
  if (!contest) return <div>Loading...</div>;
  return (
    <div className={"bg-base-100 shadow-xl rounded-xl p-4"}>
      <div className={"flex justify-center flex-col items-center"}>
        <div className="card lg:card-side bg-base-100 shadow-md">
          <figure>
            {contest.thumbnail && (
              <Image
                alt={contest.thumbnail}
                width={400}
                height={400}
                className={"rounded"}
                src={contest.thumbnail}
              />
            )}
          </figure>
          <div className="card-body">
            <h2 className="card-title">{contest.name}</h2>
            <p>{contest.description}</p>
          </div>
        </div>

        <div className={"p-4"}>
          {!contestEntry && (
            <Formik
              initialValues={{
                contest_id: contest.id,
                name: "",
                email: "",
                subscribed: false,
                termsOfService: false,
              }}
              onSubmit={submitForm}
            >
              {(props) => (
                <Form className={"flex flex-col gap-y-6"}>
                  <TextInput name={"name"} label={"Name"} />
                  <TextInput name={"email"} label={"Email"} />
                  <div>
                    <CheckboxInput
                      name={"termsOfService"}
                      value={true}
                      label={
                        <span>
                          I agree to the{" "}
                          <Link
                            className={"underline"}
                            href={"#"}
                            onClick={(e: any) => {
                              e.preventDefault();
                              setShowTos(true);
                            }}
                          >
                            terms of game play
                          </Link>{" "}
                          <span className={"text-gray-500 text-xs"}>
                            (very brief, read without leaving page)
                          </span>
                        </span>
                      }
                    />
                  </div>
                  <div>
                    <CheckboxInput
                      name={"subscribed"}
                      value={true}
                      label={
                        contest.marketing_opt_in_prompt
                          ? contest.marketing_opt_in_prompt
                          : "Send me an occasional email about comedy events relevant to me."
                      }
                    />
                  </div>
                  <div className={"text-center"}>
                    <button className={"btn-primary btn"}>Play</button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>

        <dialog
          className={`${showTos ? "modal-open" : ""} modal modal-bottom sm:modal-middle  text-xs`}
        >
          <form method="dialog" className="modal-box">
            <div className={"flex flex-row gap-x-6 justify-evenly"}>
              <h3 className="font-bold text-xl">Terms of Game Play</h3>
              <button
                type={"button"}
                onClick={() => setShowTos(false)}
                className="btn btn-secondary btn-md"
              >
                Close
              </button>
            </div>
            <ol className={"list-decimal ml-4"}>
              <li className={"py-2"}>
                <strong>Randomness:</strong> Winners are randomly determined by
                Jump Comedy
              </li>
              <li className={"py-2"}>
                <strong>Prizes:</strong> Prizes are supplied and managed by the
                venue/promoter
              </li>
            </ol>
            <div className={"divider"} />

            {contest.rules && (
              <>
                <div dangerouslySetInnerHTML={{ __html: contest.rules }}></div>
                <div className={"divider"} />
              </>
            )}
            {contest.terms && (
              <iframe
                className={"border-2"}
                height={200}
                width={"100%"}
                srcDoc={contest.terms}
              />
            )}
          </form>
        </dialog>
        {contestEntry && (
          <ContestGames contest={contest} contestEntry={contestEntry} />
        )}
      </div>
    </div>
  );
}
